import { API } from 'aws-amplify'
export default {
  mounted () {
    window.gmoGetTokenCallBack = this.gmoGetTokenCallBack
  },
  methods: {
    // レッスンの組み合わせ表取得
    getLessonRelationMaster: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/masters/enable_lessons'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // レッスンマスタの取得
    getLessonMaster: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/masters/lessons'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 入会済み判定API
    // request = {
    //   email: string;
    //   jid: string;
    // };
    postJoined: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/statuses'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 入会済み判定API（エラー非表示）
    // request = {
    //   email: string;
    //   jid: string;
    // };
    postJoinedNoError: async function (body) {
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/statuses'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 入会申請
    // request:{
    //   application: {
    //     school_id: String,
    //     course_id: String,
    //     class_id: String,
    //     lesson_id: String,
    //     preferred_date: yyyy-mm-dd
    //   },
    //   participant: { // 子
    //     name: String,
    //     furigana: String,
    //     birthday: String,
    //     gender: String,
    //     request_position: string
    //     attention: string;
    //   },
    //   contact: { // 親
    //     name: String,
    //     furigana: String,
    //     address: String,
    //     email: String,
    //     phone_number: String,
    //     birthday: String,// 子がある場合不要
    //     gender: String// 子がある場合不要
    //     emergency_name : string; 緊急連絡先
    //     emergency_tel : string; 緊急連絡先電話番号
    //     emergency_memo : string; 緊急連絡先メモ ※画面には無い項目（DBの予備項目）
    //   }
    // }
    postRegister: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 会員追加
    // {
    //   name: string;
    //   furigana: string;
    //   birthday: string;
    //   gender: string;
    //   school_id: number;
    //   course_id: number;
    //   class_id: number;
    //   lesson_id: number;
    //   request_position: number;
    //   preferred_date: yyyy-mm-dd,
    //   attention: string
    // }
    // 生徒→契約者の順に入会する場合はコース追加にて契約者の入会を行う
    postMemberAdd: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/member'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コース追加
    // {
    //   user_id: number;
    //   preferred_date: string;
    //   school_id: number;
    //   course_id: number;
    //   class_id: number;
    //   lesson_id: number;
    //   birthday?: string;
    //   gender?: string;
    //   attention?: string;
    //   request_position: number | null;
    // };
    postCourseAdd: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/course'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験入会用のscheduleデータ取得
    // request: {
    //   school_id: String,
    //   course_id: String,
    //   class_id: String,
    //   lesson_id: String
    // }
    getCourseScheduleData: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/experiences/schedules'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験申請
    // reqest: {
    //   user_id: number | null;
    //   name: string;
    //   furigana: string;
    //   birthday: string;
    //   phone_number: string;
    //   email: string;
    //   gender: number;
    //   school_id: number;
    //   course_id: number;
    //   class_id: number;
    //   lesson_id: number;
    //   prefferd_schedule_id: number;
    //   request_position: number | null;
    //   attention: string | null;
    //   classification: number;
    // }
    postTrial: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/experiences'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スケジュール取得
    // request: {
    //   username: String,
    //   start_date: Long,
    //   end_date: Long
    // }
    getScheduleData: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 出席表取得
    // request: {
    // }
    getAttendanceList: async function (scheduleId) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules/' + scheduleId + '/attendance'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スケジュール開催状況変更
    // request: {
    //   is_holding: Boolean
    // }
    postScheduleHolding: async function (scheduleId, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules/' + scheduleId
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 出欠状況変更
    // request: {
    //   is_attend: number 0:def 1:出席 2:欠席
    // }
    putChangeAttend: async function (scheduleId, userId, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules/' + scheduleId + '/user/' + userId + '/attendance'

      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スケジュール登録
    // request: {
    //   id: number | undefined
    //   start_date: string;
    //   end_date: string;
    //   title: string;
    //   place: string;
    //   comment: string;
    //   category: number; 0=通常 1=休暇
    //   dest: [{
    //     school_id: number;
    //     course_id: number;
    //     class_id: number;
    //     lesson_id: number;
    //   }];
    // };
    postRegisterSchedule: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチへの連絡
    // request: {
    //   notice_list: [{
    //     student_id: number;
    //     schedule_id: number;
    //     message_type: number;
    //     message: string;
    //   }]
    // };
    postRegisterContactToCoach: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices/user'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 生徒一覧取得
    // request: {
    // }
    getStudentsList: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/list/students'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ一覧取得
    // request: {
    // }
    getNews: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ既読者一覧取得
    // request: {
    // }
    getReadedMemberList: async function (noticeId) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices/' + noticeId + '/readlist'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ登録
    // request: {
    //   title: String,
    //   content: String,
    //   delivery_date: yyyy-mm-dd hh:mi:ss,
    //   target: number; // 1: クラス 2: 全体 3: 男性 4: 女性 5: 未選択 6: 男性＋女性 7: 男性＋未選択 8: 女性＋未選択 9: 男性＋女性＋未選択
    //   lessons: [
    //     {
    //       school_id: String, 2:全体、3:男性、4:女性の場合、nullを設定
    //       class_id: String, 2:全体、3:男性、4:女性の場合、nullを設定
    //       course_id: String, 2:全体、3:男性、4:女性の場合、nullを設定
    //       lesson_id: String 2:全体、3:男性、4:女性の場合、nullを設定
    //     }
    //   ],
    // },
    postNews: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // request: {
    //   notices_from_school_id: number;
    //   document_url1: String,
    //   document_url2: String,
    //   document_url3: String,
    //   document_url4: String,
    //   document_url5: String
    // }
    postNewsDocument: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices_document_url'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ削除
    // request: {
    // },
    deleteNews: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices/' + id

      await API.del(apiName, path).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ既読登録
    // request: {
    // }
    postReadNews: async function (noticeId) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notices/' + noticeId + '/read'
      const myInit = {
        response: true
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 個人情報取得
    // request: {
    // }
    getPersonalInfo: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/profile'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        self.$store.commit('setLoginUserId', returnResponse.id)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 個人情報取得(エラー非表示)
    // request: {
    // }
    getPersonalInfoDontShowError: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/profile'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        self.$store.commit('setLoginUserId', returnResponse.id)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 受講コース変更
    // request: {
    //   id: String, //受講者ID
    //   request_type: Number,//申請種別（1：入会 2：変更 3：退会 4：休会 5：休会解除）
    //   preferred_date: yyyy-mm-dd
    //   old_state: {// 2、3、4、5 の場合、状態を変えたいコースの情報を設定 1の場合、null
    //     school_id: Number,
    //     class_id: Number,
    //     course_id: Number,
    //     lesson_id: Number
    //   },
    //   new_state: {//1、2の場合、新規登録のIDを設定 3、4、5の場合、null
    //     school_id: Number,
    //     class_id: Number,
    //     course_id: Number,
    //     lesson_id: Number
    //   }
    // }
    postChangeCourse: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/profile/enable_lessons'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 個人情報変更
    // request: {
    //  name: String,
    //  furigana: String,
    //  phone_number: String,
    //  email: String,
    //  post_number: String,
    //  address: String,
    //  emergency_phone_number: String,// 管理項目要検討
    //  birthday: Strking,
    //  gender: 0 | 1 | 2, //0:男性 1:女性 2:その他,
    //  emergency_name : string;
    //  emergency_tel  : string;
    //  emergency_memo : string;
    // },
    postPersonalInfo: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/profile'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 受講者情報変更
    // request: {
    //   id: String,
    //   name: String,
    //   furigana: String,
    //   birthday: Long,
    //   gender: 0 | 1 | 2, //0:男性 1:女性 2:その他
    // },
    postStudentInfo: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/profile/student'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 受講者詳細取得
    // request: {
    //   id: String
    // }
    getStudentInfo: async function (studentId) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/details/student/' + studentId
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 自担当コース情報取得
    // request: {
    //   id: String
    // }
    getMyCourse: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/details/course'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験申請一覧API（未承認）
    getTrialRequest: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/experiences/list'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験承認API
    // request:
    // {
    //     student_id: number  // 変更
    //     school_id: number  // 変更
    //     course_id: number  // 変更
    //     class_id: number  // 変更
    //     lesson_id: number  // 変更
    //     fixed_schedule_id: number // 変更
    //     status: number //1 = 承認 9 = 否認
    // }
    postTrialRequestApproval: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/experiences/fixed_date'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 申請一覧API
    // request:
    // {
    //    request_type: number | null;
    //    status: number | null;
    // }
    // request_type 1：入会、2：変更、3：退会、4：休会、5：休会解除
    // status 1：受付、2：承認、3：キャンセル待ち、9：否認
    //
    // request_type＝1：入会 → old_lessonなし、new_lesson入会申込のクラス
    // request_type＝2：変更 → old_lesson現在のクラス（変更前クラス）、new_lesson変更後のクラス
    // request_type＝3：退会 → old_lesson現在のクラス（退会したいクラス）、new_lessonなし
    // request_type＝4：休会 → old_lesson現在のクラス（休会したいクラス）、new_lessonなし
    // request_type＝5：休会解除 → old_lesson現在のクラス（解除したいクラス）、new_lessonなし
    // 未確認: コーチの確認前
    // →state＝1
    // コーチ確認済: コーチの確認後、請求担当の〆前
    // →state＝2
    // 〆済み: 請求担当の〆後 支払い処理前
    // →state＝3or4
    // 未納（お支払い残高）: 支払い処理後 支払えなかった場合
    // →state＝5 & payment_state<>1
    // 請求済: 支払い処理後 正常完了
    // →state＝5 & payment_state＝1
    getRequestList: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/approval/list'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求承認API
    // request:
    // {
    //   user_id: number     // 承認対象ユーザID
    //   lessons_id: number   // 承認対象ユーザの授業ID、enable_lessons_idを設定
    //   changed_lessons_id: number | null   // 授業変更の場合、変更先の授業ID、enable_lessons_idを設定
    //   request_type: number  // 授業の状態   1入会   2変更   3退会  4休会  5休会解除
    //   status: number  // 承認ステータス  1受付  2承認 3キャンセル待ち 9否認
    //   reason: string | null  //否認の場合、否認理由
    //   preferred_date: string
    // }
    postRequestApproval: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/approval'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求承認API
    // request:
    // {
    //   bills:[
    //   {
    //     id: number,  // 請求ID
    //     status: number  // 1：仮,2：確定
    //   }]
    // }
    // status=2 コーチ承認 status=3 請求担当承認
    postBillsApproval: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/change_bill_status'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求一覧取得API
    // request: {
    //   bill_month=yyyy-mm-dd
    // }
    getBillsList: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/inquiry/bills'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求一覧取得API
    // request: {
    //   bill_details: [{
    //       bills_id: number;
    //       bill_month: string;
    //       bill_type: number;
    //       amount: number;
    //       user_id: number;
    //       enable_lessons_id: number | null;
    //   }]
    // }
    postBillDetail: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/register_bill_detail'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求明細削除API
    // request:
    // {
    // }
    deleteBillDetail: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/del_bill_detail/' + id

      await API.del(apiName, path).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求明細変更API
    // request: {
    //   bill_details_id: number;
    //   bill_month: string;
    //   bill_type: number;
    //   amount: number;
    //   enable_lessons_id: number | null;
    // }
    putBillDetail: async function (body, id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/update_bill_detail'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 通知取得API
    getNotices: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/notice/displayed_notices'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求費目取得API
    getBillCategory: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/bill_expense_category'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求項目取得API
    getBillItem: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/bill_expense_item'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求費目更新削除
    // request: {
    //   bill_category: number | null; // 追加の場合、null 更新・削除の場合値設定
    //   bill_category_name: string;
    //   is_delete: number; // 0:有効、1:無効 → 削除時、1を設定
    // }
    postBillCategory: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/bill_expense_category'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 請求項目更新削除
    // request: {
    //   bill_type: number | null; // 追加の場合、null 更新・削除の場合値設定
    //   bill_type_name: string;
    //   bill_category: number;
    //   is_delete: number; // 0:有効、1:無効 → 削除時、1を設定
    // }
    postBillItem: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/bill_expense_item'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // クレジットカード初回登録API
    // request: {
    //   MemberId: string;
    // }
    postCreditCardRegister: async function (body) {
      const self = this
      const returnResponse = {
        data: null
      }
      const apiName = 'integrationPayment'
      const path = '/registers/GMOMember'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse.data = response
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // gmoのtoken取得コールバック関数
    // request: {
    //   MemberId: string;
    // }
    gmoGetTokenCallBack: function (value) {
      this.$store.commit('setGmoCardInfo', value)
    },
    // クレジットカード変更登録API
    // request: {
    //   MemberId: string;
    //   Token: string;
    //   CardSeq: string | null;
    // }
    postCreditCardChangeRegister: async function (body) {
      const self = this
      const returnResponse = {
        data: null
      }
      const apiName = 'integrationPayment'
      const path = '/registers/card'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse.data = response
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 口座変更登録AAPI
    // request: {
    //   MemberId : string
    //   BankCode : string
    //   BranchCode : string
    //   AccountType : string
    //   AccountNumber : string
    //   AccountName : string
    //   AccountNameKanji : string
    //   ConsumerDevice : 'pc',
    //   RetURL: string
    // }
    postBankAccountRegister: async function (body) {
      const self = this
      const returnResponse = {
        data: null
      }
      const apiName = 'integrationPayment'
      const path = '/registers/account'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse.data = response
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチ一覧取得
    // request: {
    // }
    getCoachList: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/coach/list'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチ登録API
    // request: {
    //   name: string;
    //   furigana: string;
    //   email: string;
    //   classification: number; // 3:コーチ,4:請求担当,5:管理者
    // }
    postCoachRegister: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/coach/register'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチ変更API
    // request: {
    //   coach_id: number;
    //   name: string;
    //   furigana: string;
    //   email: string;
    //   classification: number;
    //   is_delete: boolean | number; // 0:���効、1:無効 → 削除時、1を設定
    // }
    putCoachRegister: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/coach/register'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチ担当コース登録
    // request: {
    //   coach_id: number;
    //   lessons: [
    //     {
    //       school_id: number;
    //       course_id: number;
    //       class_id: number;
    //       lesson_id: number;
    //     }
    //   ];
    // }
    postCoachCourseRegister: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/coach/lessons'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチ担当コース追加変更削除
    // request: {
    //   coach_id: number;
    //   lessons: [
    //     {
    //       charge_lesson_id: number | null; // 追加の場合、null 更新・削除の場合値設定
    //       school_id: number;
    //       course_id: number;
    //       class_id: number;
    //       lesson_id: number;
    //       is_delete: boolean; // 0:有効、1:無効 → 削除時、1を設定
    //     }
    //   ];
    // }
    putCoachCourseRegister: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/coach/lessons'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setMyCourse', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スクール登録
    // request: {
    //   school_name: string;
    //   facility_name: string | null;
    //   facility_address: string | null;
    //   facility_phone_number: string | null;
    //   facility_latitude: number | null;
    //   facility_longitude: number | null;
    //   facility_information: string | null;
    //   document_url: string | null;(最大500文字)
    // }
    postSchoolMaster: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/schools'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スクール更新
    // request: {
    //   school_name: string;
    //   facility_name: string | null;
    //   facility_address: string | null;
    //   facility_phone_number: string | null;
    //   facility_latitude: number | null;
    //   facility_longitude: number | null;
    //   facility_information: string | null;
    //   document_url: string | null;(最大500文字)
    // }
    putSchoolMaster: async function (id, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/schools/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スクール削除
    // request:
    // {
    // }
    deleteSchoolMaster: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/schools/' + id
      const myInit = {
      }

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コース登録
    // request: {
    //   course: string;
    //   information: string | null;
    //   document_url: string | null;(最大500文字)
    // }
    postCourseMaster: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/courses'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コース更新
    // request: {
    //   course: string;
    //   information: string | null;
    //   document_url: string | null;(最大500文字)
    // }
    putCourseMaster: async function (id, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/courses/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コース削除
    // request: {
    // }
    deleteCourseMaster: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/courses/' + id
      const myInit = {
      }

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // クラス登録
    // request: {
    //   class: string;
    //   target_grade_from: number;
    //   target_grade_to: number;
    // }
    postClassMaster: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/classes'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // クラス更新
    // request: {
    //   class: string;
    //   target_grade_from: number;
    //   target_grade_to: number;
    // }
    putClassMaster: async function (id, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/classes/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // クラス削除
    // request: {
    // }
    deleteClassMaster: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/classes/' + id
      const myInit = {
      }

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // レッスン登録
    // request: {
    //   lesson: string;
    //   training_time: string;
    //   training_start_time: string; (hh:mm:ss)
    //   training_end_time: string; (hh:mm:ss)
    //   lesson_type: number; クラス曜日構成(OR もしくは 曜日単数:0, AND:1)
    //   lessons_day: { day_id: number; 曜日ID(月~日, 0~6) is_target: number; スケジュール展開フラグ }[];
    // }
    postLessonMaster: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/lessons'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // レッスン更新
    // request: {
    //   lesson: string;
    //   training_time: string;
    //   training_start_time: string; (hh:mm:ss)
    //   training_end_time: string; (hh:mm:ss)
    //   lesson_type: number; クラス曜日構成(OR もしくは 曜日単数:0, AND:1)
    //   lessons_day: { day_id: number; 曜日ID(月~日, 0~6) is_target: number; スケジュール展開フラグ }[];
    // }
    putLessonMaster: async function (id, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/lessons/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // レッスン削除
    // request:
    // {
    // }
    deleteLessonMaster: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/lessons/' + id
      const myInit = {
      }

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 組み合わせ登録
    // - school_id: number;
    // - course_id: number;
    // - class_id: number;
    // - lesson_id: number;
    // - enable_lessons_prices: number;
    // - reception_status: number;
    // - maxmum: number;
    // - is_stopped: number;
    // - enable_lessons_dest: [
    //     {
    //       grade: number;
    //       next_enable_lessons_id: number | null;
    //       is_continued: number;
    //     }
    //   ]
    postEnableLesson: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/enable_lessons'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        console.log(returnResponse.result_information)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 組み合わせ更新
    // - school_id: number;
    // - course_id: number;
    // - class_id: number;
    // - lesson_id: number;
    // - enable_lessons_prices: number;
    // - reception_status: number;
    // - maxmum: number;
    // - is_stopped: number;
    // - enable_lessons_dest: [
    //     {
    //       grade: number;
    //       next_enable_lessons_id: number | null;
    //       is_continued: number;
    //     }
    //   ]
    putEnableLesson: async function (body, id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/enable_lessons/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        console.log(returnResponse.result_information)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 組み合わせ削除
    deleteEnableLesson: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/enable_lessons/' + id
      const myInit = {}

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        console.log(returnResponse.result_information)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setLessonMaster', {})
          self.$store.commit('setLessonRelation', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 予定展開API
    // request: {
    //  startDate: 開始日(YYYY-MM-DD)
    //  endDate: 終了日(YYYY-MM-DD)
    //  enableLessonIds: 対象クラス(Array)
    // }
    postMonthSchedule: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules/bulk'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        console.log(returnResponse.result_information)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ未読者へのメール送信
    // request = {
    //   title: string; // お知らせのタイトル
    //   emailList: string[]; // メールアドレス
    // };
    postSendNotReader: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/mail/send_unread'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 出席者へのメール送信
    // request = {
    //   emailList: string[]; // メールアドレス
    // };
    postSendAttendance: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/mail/send_attend'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        console.log(returnResponse.result_information)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 生徒csv抽出
    // request = {
    //   studentsId: [ number ];
    // };
    postCsvStudents: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/csv/students'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        console.log(returnResponse.result_information)
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 配信クラス取得API
    // request = {
    //   "school_id": number | null ,
    //   "course_id":number | null ,
    //   "class_id":number | null ,
    //   "lesson_id": number | null
    // };
    postEnableLessonsList: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/util/enable_lessons_list'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 年度更新一覧取得
    // request: {
    // }
    getNextYearLesson: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/next_year_courses/list'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 年度更新レッスン情報取得（ユーザ別）
    // request: {
    // }
    getNextYearLessonUser: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/next_year_courses'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 年度更新確認・承認API
    // request = {
    //   id: number;
    //   user_id: number;
    //   next_eneble_lessons_id: number;
    //   decision_status: number;
    //   status: number;
    // }
    postNextYearLesson: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/next_year_courses'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 締め日マスタ取得
    // request: {
    // }
    getBillClosingDateMaster: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/masters/bill_closing_date'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 関係者フラグ更新API
    // request: {
    //   is_concerned: number; 0:関係者ではない 1:関係者
    // }
    putConcerbed: async function (body, id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/profile/student_is_concerned/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setMyCourse', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // スケジュール一括削除
    // request:
    // {
    //   schedule_ids: []
    // }
    deleteSchedules: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/schedules'
      const myInit = {
        body: body
      }

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 自動採番ユーザネーム取得
    // request: {
    // }
    getNewUserName: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/registers/username'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // メールアドレスからusername取得
    // request: {
    //   email: string
    // }
    postGetUserName: async function (body, errorActivation = true) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/registers/email_to_username'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response
        if (returnResponse.result_information && errorActivation && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // maintenance状態取得
    // request: {
    // }
    getMaintenanceMode: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/util/get_maintenance_mode'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ取得
    // request: {
    // }
    getInformation: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/util/get_important_notice'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替えマスタ取得
    // request: {
    // }
    getTransferList: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer'
      const myInit = {
        response: true
      }
      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替え情報登録
    // request: {
    //   transfer_source: number; 振替元/組み合わせ項目ID
    //   transfer_destination: number[]; 振替先/組み合わせ項目ID
    // }
    postTransferInfo: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振替マスタ情報更新
    // request: {
    //   transfer_destination: number[]; 振替先/組み合わせ項目ID
    // }
    putTransferInfo: async function (body, id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setMyCourse', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振替マスタ情報削除
    // request:
    // {
    // }
    deleteTransferInfo: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer/' + id
      const myInit = {
      }

      await API.del(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替え申請一覧
    // request: {
    // }
    getTransferRequestList: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/procedures'
      const myInit = {
        queryStringParameters: param,
        response: true
      }
      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // ログpost
    // request: {
    //   log:string | null
    // }
    postLog: async function (text) {
      const self = this
      let returnResponse = null
      const apiName = 'public'
      const path = '/util/jid_log'
      const myInit = {
        body: {
          log: text
        }
      }
      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振替履歴取得
    // request: {
    // }
    getTransferRequestHistoryList: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/histories/' + id
      const myInit = {
        response: true
      }
      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替え申請
    // request: {
    //   user_id:number; 対象ユーザーID
    //   old_schedules_id:number; 振替元スケジュールID
    //   new_schedules_id:number; 振替先スケジュールID
    //   reason:string; 理由
    // }
    postTransferRequest: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替え候補日一覧
    // request: {
    //   schedule_id 振替元スケジュールID
    // }
    getTransferRequestableDay: async function (param, id) {
      // const self = this
      let returnResponse = null
      let errorMessage = ''
      const apiName = 'integration'
      const path = '/transfer/schedules/' + id
      const myInit = {
        response: true,
        queryStringParameters: param
      }
      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          // 都合上エラーをもみ消す。
          // self.globalApiError(returnResponse.result_information.error_message)
          errorMessage = returnResponse.result_information.error_message
          returnResponse = null
        }
        return response.data
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        console.log(errorMessage)
        throw new Error(errorMessage)
      }
    },
    // 振替申請キャンセル
    // request: {
    //   transfer_destination: number[]; 振替先/組み合わせ項目ID
    // }
    putTransferRequestCancel: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/cancel/' + id
      const myInit = {}

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        } else {
          self.$store.commit('setMyCourse', [])
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替え承認・非承認
    // request: {
    //   transfer_procedure_id:number; 振替申請ID (必須)
    //   status: number; 振替手続きステータス承認:2, 否認:9
    //   reason_category: number; 振替理由カテゴリ (承認時必須)
    //   to_user_comment: string; 申請者へのコメント (必須)
    //   processing_comment: string; 処理コメント
    // }
    postTransferRequesttApproval: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/approval'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // コーチ予定振り替え
    // request: {
    //   old_schedules_id:number; 振替元スケジュールID
    //   start_date:string; 振替先開始日 形式(YYYY-MM-DD HH:mm:ss)
    //   end_dat:string; 振替先終了日 形式(YYYY-MM-DD HH:mm:ss)e
    //   title:string; 振替先タイトル
    //   place:string ; 振替先場所
    //   comment:string | null; 振替先コメント
    // }
    postScheduleTransfer: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/schedules'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験申請一覧CSV
    // request: {
    // }
    postTrialListCsv: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/csv/experiences'
      const myInit = {
        response: true
      }
      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振り替え種別マスタ取得
    // request: {
    // }
    getTransferReasonMaster: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer_reason_categories'
      const myInit = {
        response: true
      }
      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体調カテゴリマスター取得
    getConditionCategoryMaster: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/condition_categories'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体調取得API
    // param {
    //   target_date: YYYY-MM-DD
    // }
    getConditionInfo: async function (studentId, param) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/conditions/' + studentId
      const myInit = {
        response: true,
        queryStringParameters: param
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体調更新API
    // request: {
    //   conditions: [
    //     {
    //       id: number | null; 体調管理情報ID
    //       user_id: number; 生徒ID
    //       target_date: string; 対象日付
    //       temperature: number | null; 体温
    //       condition_categories: [
    //         {
    //           id: number; 体調カテゴリID
    //           condition_name: string; 体調カテゴリ名
    //         }
    //       ];
    //     }
    //   ];
    // }
    postConditionInfo: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/conditions'
      const myInit = {
        body: body
      }
      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.errors_information.length > 0) {
          self.globalApiError(returnResponse.errors_information.join(', ').error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 年度更新期間取得API
    // param {
    // }
    getNextYearUpdateDateSpan: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuth'
      const path = '/util/get_next_year_date'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験申請Cancel
    // request: {
    //   student_id: number⇒体験申請のstudent_id
    //   school_id: number⇒体験申請のスクールID
    //   course_id: number⇒体験申請のコースID
    //   class_id: number⇒体験申請の対象ID
    //   lesson_id: number⇒体験申請のクラスID
    //   fixed_schedule_id: number | null⇒体験申請の確定したスケジュールID
    // }
    postTrialRequestCancel: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/experiences/cancel'
      const myInit = {
        body: body
      }
      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 体験申請Cancel
    // request: {
    //   student_id: number⇒体験申請のstudent_id
    //   school_id: number⇒体験申請のスクールID
    //   course_id: number⇒体験申請のコースID
    //   class_id: number⇒体験申請の対象ID
    //   lesson_id: number⇒体験申請のクラスID
    //   fixed_schedule_id: number | null⇒体験申請の確定したスケジュールID
    // }
    putRequestCancel: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/registers/cancel/' + id
      const myInit = {
        response: true
      }
      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 過去の振替可能スケジュール(振替元)一覧取得
    // param {
    //  user_id,
    //  mode 検索モード[0:未回答 1:出席 2:欠席]
    // }
    getTransfeableSchedule: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/schedules/past'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振替期限マスタ情報取得
    // param {
    // }
    getTransfeableDaySpan: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer_expiration'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          // 都合上エラーをもみ消す。
          // self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振替期限マスタ情報更新
    // request: {
    //   expiration: number; 振替期限日数
    // }
    putTransfeableDaySpan: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/masters/transfer_expiration'
      const myInit = {
        body: body,
        response: true
      }
      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 振替申請可能フラグ更新
    // request: {
    //   id スケジュールID
    //  allow_transfer_application: boolean
    // }
    putTransfeableFlag: async function (id, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integration'
      const path = '/transfer/allow_transfer_application/' + id
      const myInit = {
        body: body,
        response: true
      }
      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    }
  }
}
