import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

/* eslint-disable */
export default new createVuetify({
  components,
  directives,
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        colors: {
          primary: '#920683', // A
          'primary-darken1': '#820373', // R
          'primary-lighten1': '#F2C9EE', // C
          primaryBtnText: '#FFFFFF', // A'
          secondary: '#BFAE72', // B
          'secondary-darken1': '#3D2E00', // D
          secondaryBtnText: '#FFFFFF', // B'
          headerBack: '#920683', // E
          headerText: '#FFFFFF', // F
          logoutBtnText: '#FFFFFF', // G
          topSchoolNamePc: '#920683', // H
          topSecondaryBtn: '#BFAE72', // I
          topSecondaryBtnText: '#FFFFFF', // J
          topPrimaryBtn: '#920683', // K
          topPrimaryBtnText: '#FFFFFF', // L
          topBtnUpText: '#FFFFFF', // M
          primaryOutLineBtnText: '#920683', // N
          secondaryOutLineBtnText: '#920683', // O
          topBtnUpTextSp: '#920683', // P
          topBtnBackSp: '#FFFFFF', // Q'
          eventHeaderBack: '#FFB6FF',
          eventHeaderText: '#FFFFFF',
          eventTopBorderSub: '#BFAE72',
          accent: '#920683', // datepickerなどの色
          formBackground: '#F6f6f6',
          'error-lighten3': '#ffa99e',
          'error-darken1': '#df323b',
          'error-darken2': '#bf0025',
          'error-darken3': '#9f0010',
          'success-darken1': '#2d9437',
          'success-darken2': '#00791e',
          'success-darken3': '#006000',
          'info-darken3': '#004d9f',
          'info-lighten3': '#95e8ff'
          
        }
      },
      dark: {
        colors: {
          primary: '#FF5252',
          secondary: '#43A047',
          accent: '#162988',
          formBackground: '#F6f6f6'
        }
      }
    }
  },
  date: {
    formats: {
      dayOfMonth: (date) => date.getDate()
    }
  }
})
